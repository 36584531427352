import React from 'react';
import qs from 'query-string';

import { loginUserWithFacebook } from '../../store/session';
import OAuthCallbackModal from '../../views/Modals/OAuthCallbackModal';
import { Index } from '../index';

interface FacebookSuccess {
  access_token?: string;
  state?: string;
}

interface FacebookError {
  error_code: string;
  error_message: string;
}

export default function SignFacebookPage() {
  return (
    <Index>
      <OAuthCallbackModal
        action={loginUserWithFacebook}
        parseResponse={location => {
          const query = qs.parse(location.search) as unknown as FacebookError;
          if ('error_code' in query) {
            throw new Error(query.error_message ?? 'flash.facebookSignInError');
          }
          const fragments = qs.parse(location.hash) as FacebookSuccess;
          if ('access_token' in fragments) {
            let original_uri;
            if ('state' in fragments) {
              original_uri = (qs.parse(fragments.state) as { original_uri: string }).original_uri;
            }
            return {
              access_token: fragments.access_token,
              original_uri,
            };
          }
          throw new Error('flash.facebookSignInError');
        }}
      />
    </Index>
  );
}
